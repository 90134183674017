import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Navbar from '../../../Components/commonComponents/navbar/Navbar'
import ArrowBack from "../../../assets/images/favoriteProperty/arrow-back.svg"
import url from "../../../utils/services/urls.json"
import { postMethodWithToken } from '../../../utils/services/apis';
import { getUserEmail, getUserId } from "../../../utils/services/common";
import FormControl from '@mui/material/FormControl';
import { MenuItem, Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormInput from '../../../Components/Inputs/FormInput/FormInput';
import "./AddPayment.scss"

const AddPaymentContainer = styled.div`
background: rgb(242, 242, 247);
height: auto;
font-family: Proxima_nova_reg;
`

const AddPayment = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [couponCode, setCouponCode] = useState("")
    const [subscriptionPackage, setSubscriptionPackage] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const handleCouponCodeChanges = (event) => {
        setCouponCode(event.target.value)
    }
    const handlePackageChanges = (event) => {
        setSubscriptionPackage(event.target.value)
    }

    const paypalOptions = {
        // 'client-id': 'AbysTaXRkDPWv-JqxyNvz6AFQzvCYiq-Mftc2y2x3pJ3XPq6dj5hZL2kv3tnt1ZK0oBe98OObknnagJj',
        // 'client-id': "AcMXdFC-3VdF1HtFOJY8nEoF0CRuIcdMoVTrajLnwaR8nQQK6ze_ueDxevuKovSAM80lWUj283mpnCCl",
        'client-id':"AQRvSUPjROQYwZnk-TK9YR4rQ0a1JOyuzuhloudr75QbDKWXnoqH6W7r9z-VaibaogoawEg-1H89-iJ5",
        'disable-funding': 'card',
        currency: 'USD',
    };
    var intervalId;

    // const handleTransactionPayment = (value) => {
    //     if (location.pathname.includes('/Dashboard/ManageSubscription/PaymentDetail')) {
    //         intervalId = setInterval(() => {
    //             if (location.pathname.includes('/Dashboard/ManageSubscription/PaymentDetail')) {
    //                 handleVerifyPayment(value)
    //             }
    //         }, 5000);
    //     }
    //     setIsLoading(true);
    // }

    const handleTransactionPayment = async (verifyValue) => {
        let body = {
            // "propertyMaster": params.id,
            "transaction_status": verifyValue?.status,
            "payment_id": verifyValue?.purchase_units?.[0]?.payments?.captures?.[0]?.id,
            "email_address": getUserEmail(),
            "payer_id": verifyValue?.payer?.payer_id,
            "master_sub_id": +localStorage.getItem("manageSubscriptionId")
        }
        await postMethodWithToken(url.PostPaymentVerify, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                if (location.pathname.includes('/Dashboard/ManageSubscription/PaymentDetail')) {
                    intervalId = setInterval(() => {
                        if (location.pathname.includes('/Dashboard/ManageSubscription/PaymentDetail')) {
                            handleVerifyPayment(verifyValue)
                        }
                    }, 5000);
                }
                setIsLoading(true);
            }
        })
    }

    useEffect(() => {
        // if (location.pathname !== '/Dashboard/ManageSubscription/PaymentDetail') {
        return () => clearInterval(intervalId);
        // }
    }, [])


    const handleVerifyPayment = async (value) => {
        let paymentBody = {
            "user_id": +getUserId(),
            "payment_id": value?.purchase_units?.[0]?.payments?.captures?.[0]?.id,
            "master_sub_id": +localStorage.getItem("manageSubscriptionId")
        }
        await postMethodWithToken(url.PostVerifyPayment, paymentBody).then((response) => {
            if (response.status === 200 || response.status === 201) {
                localStorage.removeItem("manageSubscriptionId")
                navigate(`/Dashboard/ManageSubscription`)
                clearInterval(intervalId)
            }
        })
    }

    return (
        <>
            {!isLoading &&
                <div style={{ overflowX: 'hidden', height: "100vh", background: "rgb(242, 242, 247)" }} className='searchdetail'>
                    <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
                        <Navbar />
                    </div>
                    <AddPaymentContainer>
                        <Row style={{ padding: "80px 25px 20px 25px" }}>
                            <Col lg={1}>
                                <div className="arrow-box-profile" onClick={() => navigate(-1)}>
                                    <img src={ArrowBack} alt="" />
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className="payment-detail-change" style={{ boxShadow: "0px 2px 8px 0px #0000001F" }}>
                                    <div className="payment-heading" >Checkout</div>
                                    <div className="d-flex justify-content-center">
                                        <div className="method-heading mt-4">Payment Method</div>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                        {/* <div className="card-container">
                                    <div className="d-flex justify-content-center"><img src={CardImage} /></div>
                                    <div className="d-flex justify-content-center credit-heading">Credit Card</div>
                                </div> */}

                                        <div style={{ marginLeft: "10px" }}>
                                            <PayPalScriptProvider options={paypalOptions} style={{ minHeight: '40px' }}>
                                                <PayPalButtons
                                                    createOrder={(data, actions) => {
                                                        return actions.order.create({
                                                            "intent": "CAPTURE",
                                                            purchase_units: [
                                                                {
                                                                    amount: {
                                                                        value: localStorage.getItem("listPrice")
                                                                    },
                                                                },
                                                            ]
                                                        });
                                                    }}
                                                    style={{
                                                        color: "white",
                                                        height: 55
                                                    }}
                                                    onApprove={async (data, actions) => {
                                                        const order = await actions.order.capture();
                                                        if (order.status === "COMPLETED") {
                                                            handleTransactionPayment(order)
                                                        }
                                                    }
                                                    }
                                                />
                                            </PayPalScriptProvider>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className="listing-detail-payment">
                                    <div className="listing-main-heading">Payment details</div>
                                    <div className="payment-card">
                                        {/* <FormInput required={true} name={'Coupon Code'} style={{ width: "430px" }} value={couponCode} onChange={(e) => handleCouponCodeChanges(e)} /> */}
                                        <Row>
                                            {/* <Col lg={9} className="mt-3">
                                        <FormControl sx={{ minWidth: 250 }}>
                                            <InputLabel id="demo-simple-select-label">Subscription Package</InputLabel>
                                            <Select
                                                value={subscriptionPackage}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                onChange={(e) => handlePackageChanges(e)}
                                                sx={{
                                                    boxShadow: "none",
                                                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        border: 0,
                                                    },
                                                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <MenuItem value={10}>Mid to Long Term 10 listings</MenuItem>
                                                <MenuItem value={20}>Mid to Long Term 5 listings</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col lg={1}><div className="price-payment" style={{ marginTop: "30px" }}>${localStorage.getItem("listPrice")}</div></Col> */}
                                            {/* <Col lg={9}><div className="payment-descrption mt-4 mb-3">Processing Fee</div></Col>
                                    <Col lg={1}><div className="price-payment mt-4 mb-3">$</div></Col> */}
                                            <Col xs={9} sm={9} md={9} lg={9} className="payment-descrption mt-4 mb-3">Subscription Package</Col>
                                            <Col xs={1} sm={1} md={1} lg={1}><div className="price-payment mt-4 mb-3">${localStorage.getItem("listPrice")}</div></Col>
                                            <hr />
                                            <Col xs={9} sm={9} md={9} lg={9}><div className="payment-descrption mt-1">Total payable amount</div></Col>
                                            <Col xs={1} sm={1} md={1} lg={1}><div className="price-payment mt-1">${localStorage.getItem("listPrice")}</div></Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </AddPaymentContainer>
                </div >
            }

            {isLoading &&
                <>
                    <div className="loader-payment" >
                        <div className="loader"></div>
                        <div style={{ marginLeft: "10px" }} className="verify-description">Please Wait For Payment Verify...</div>
                    </div>
                    <div className="loader-description" >
                        <div className="verify-description-mobile">Please Wait For Payment Verify...</div>
                    </div>
                </>
            }

        </>
    )
}

export default AddPayment
