import React, { useEffect, useState } from 'react'
import MobileImage from "../../../assets/images/landingPage/iPhone 13 Pro.svg"
import FaceBook from "../../../assets/images/landingPage/facebook.svg"
import TwitterFight from "../../../assets/images/landingPage/twitter-flight-school.svg"
import Instagram from "../../../assets/images/landingPage/instagram.svg"
import FooterIcon from "../../../assets/images/landingPage/footer-icon.svg"
import "./FooterSection.scss"
import { Container, Row, Col } from 'react-bootstrap'
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Modal from "react-modal"
import ContinueButton from '../../../Components/Buttons/ContinueButton/ContinueButton';
import { toast } from 'react-toastify'
import { postMethod } from '../../../utils/services/apis'
import url from "../../../utils/services/urls.json"

const FooterSection = React.forwardRef((props, ref) => {
    console.log(props, ref, "4545")
    const [opneModal, setOpenModal] = useState(false);
    const [continueButton, setcontinueButton] = useState(false);
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [message, setMessage] = useState("")
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)) {
            setcontinueButton(true)
        } else {
            setcontinueButton(false)
        }
    }, [emailAddress])

    useEffect(() => {
        if (opneModal) {
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
            }, 0);
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [opneModal]);

    const styleWithRelist = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-50%, -50%)',
            width: screenSize.width <= 575 ? "80vw" : screenSize.width <= 800 ? "50vw" : screenSize.width <= 900 ? "50vw" : screenSize.width <= 950 ? "50vw" : screenSize.width <= 1100 ? "40vw" : "35vw",
            height: screenSize.width <= 575 ? "72vh" : screenSize.width <= 800 ? "60vh" : screenSize.width <= 900 ? "53vh" : screenSize.width <= 950 ? "46vh" : screenSize.width <= 1100 ? "42vh" : "60vh",
            padding: "0px",
        },
    }


    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value.trimStart())
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value.trimStart())
    }
    const handleEmailChange = (event) => {
        setEmailAddress(event.target.value.trimStart())
    }
    const handleMessageChange = (event) => {
        setMessage(event.target.value.trimStart())
    }
    const handleOpenModal = () => {
        setOpenModal(true)
        setFirstName("")
        setLastName("")
        setEmailAddress("")
        setMessage("")
    }
    const closeModal = () => {
        setOpenModal(false)
    }
    const handlePostContact = async () => {
        let body = {
            "first_name": firstName,
            "last_name": lastName,
            "email": emailAddress,
            "message": message
        }
        if (firstName !== "" && lastName !== "" && emailAddress !== "" && message !== "") {
            if (continueButton) {
                await postMethod(url.postContact, body).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        toast.success(response?.data?.success)
                        setOpenModal(false)
                    }
                })
            }
        } else {
            toast.error("Please Fill All Field")

        }
    }
    return (
        <>
            <div>
                <div class="container mt-5 ps-2 pe-2 ps-md-1 pe-md-1">
                    <div class="row mobile-container justify-content-center align-items-center">
                        <div className="col-md-2" />
                        <div class="col-md-4 d-flex justify-content-center mb-3 mb-md-0">
                            <img src={MobileImage} alt="Mobile Image" class="img-fluid" />
                        </div>
                        <div class="col-md-6 pe-xs-0 ps-sm-0">
                            <div class="mobile-section">
                                <div class="mobile-heading">Find the new Home on the Go specially crafted for Federals</div>
                                <div class="web-heading">Check our website on mobile</div>
                                <div class="web-description">Where convenience is your Fingertips</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-section row" ref={ref} id="about">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-7">
                        <div className="about-heading about-us">About Us</div>
                        <div className='about-us'>GOVHOMES is a platform designed for U.S. Government and Members of the Armed Forces, especially those serving overseas or on temporary assignments away from home. The platform was conceptualized by a U.S. Government employee who served on multiple TDYs and overseas assignments and understood the importance of having access to a reliable housing network.</div>
                        <div className='about-us about-us-description'>With many of the challenges associated with serving away from home, managing your property can be one of the most stressful aspects. GOVHOMES provides a solution for government and armed forces officials by providing a rental housing platform exclusive for government officials to allow them to find or list their property. Public servants are held to a high standard and typically go through various background checks. GOVHOMES leverages this extra layer of security to provide a secure rental platform minimizing the chances of having an unreliable landlord or tenant. GOVHOMES is also a great solution for those seeking short-term rentals. GOVHOMES has an integrated friend list allowing you to filter your listings to a select list of individuals. Join today and take advantage of your network!</div>
                        <div className='about-us about-us-description'>GOVHOMES is a private platform and not associated with the U.S. Government or Armed Forces.</div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5 d-none d-md-block">
                        <div className="row ml-2 listing-heading about-us">
                            <div className="col-sm-4 col-md-4 col-lg-4">GovHome.com</div>
                            <div className="col-sm-4 col-md-4 col-lg-4">Our Company</div>
                            <div className="col-sm-4 col-md-4 col-lg-4">Follow us on</div>
                        </div>
                        <div className="row ml-2 listing-description about-us">
                            <div className="col-4">Information for Owners</div>
                            <div className="col-4">Imprint</div>
                            <div className="col-4" style={{ marginTop: "-3px" }}>
                                <div className="d-flex m-0 p-0">
                                    {/* <img src={FaceBook} alt="" style={{ width: "35px", height: "40px", paddingRight: "10px" }} /> */}
                                    <img src={TwitterFight} alt="" style={{ width: "35px", height: "40px", paddingRight: "10px" }} />
                                    <img src={Instagram} alt="" style={{ width: "30px", height: "40px" }} />
                                </div></div>
                        </div>
                        <div className="row ml-2 listing-description about-us">
                            <div className="col-4" style={{ cursor: "pointer" }} onClick={() => handleOpenModal()}>Help & Contact</div>
                            <div className="col-4">Terms and Conadition</div>
                            <div className="col-4"></div>
                        </div>
                        <div className="row ml-2 listing-description about-us">
                            <div className="col-4"></div>
                            <div className="col-4 line-text">Privacy</div>
                            <div className="col-4"></div>
                        </div>
                        <div className="row ml-2 listing-description about-us">
                            <div className="col-4"></div>
                            <div className="col-4 line-text-num">Cookie Setting</div>
                            <div className="col-4"></div>
                        </div>
                    </div>
                    <div className='d-block d-md-none'>
                        <Container className='ps-0'>
                            <Row className='mt-1'>
                                <Col xs={12} className='listing-heading about-us'>GovHome.com</Col>
                                <Col xs={12} className='listing-description about-us pb-2'>Information for Owners</Col>
                                <Col xs={12} className='listing-description about-us ' onClick={() => handleOpenModal()}>Help & Contact</Col>

                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} className='listing-heading about-us'>Our Company</Col>
                                <Col xs={12} className='listing-description about-us  pb-2'>Imprint</Col>
                                <Col xs={12} className='listing-description about-us  pb-2'>Terms and Conditions</Col>
                                <Col xs={12} className='listing-description about-us  pb-2'>Privacy</Col>
                                <Col xs={12} className='listing-description about-us'>Cookie Settings</Col>

                            </Row>
                            <Row className='mt-2'>
                                <Col xs={12} className='listing-heading about-us'>Follow us on</Col>
                                <Col xs={12} className='listing-description about-us'>
                                    {/* <img src={FaceBook} alt="" style={{ width: "35px", height: "40px", paddingRight: "10px" }} /> */}
                                    <img src={TwitterFight} alt="" style={{ width: "35px", height: "40px", paddingRight: "10px" }} />
                                    <img src={Instagram} alt="" style={{ width: "30px", height: "40px" }} />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <div class="footer-section">
                    {/* <div class="footer-value">
                    <div class="footer-column">
                        <div class="footer-heading">Houses on Rent in Houston</div>
                        <div class="footer-heading">Houses on Rent in New York</div>
                        <div class="footer-heading">Houses on Rent in Chicago</div>
                        <div class="footer-heading">Houses on Rent in San Francisco</div>
                        <div class="footer-heading">Houses on Rent in Iowa</div>
                        <div class="footer-heading">Houses on Rent in Phoenix</div>
                        <div class="footer-heading">Houses on Rent in Philadelphia</div>
                    </div>
                    <div class="footer-column">
                        <div class="footer-heading">Houses on Rent in San Antonio</div>
                        <div class="footer-heading">Houses on Rent in Dallas</div>
                        <div class="footer-heading">Houses on Rent in San Jose</div>
                        <div class="footer-heading">Houses on Rent in Austin</div>
                        <div class="footer-heading">Houses on Rent in Jacksonville</div>
                        <div class="footer-heading">Houses on Rent in Fort Worth</div>
                        <div class="footer-heading">Houses on Rent in Columbus</div>
                    </div>
                    <div class="footer-column">
                        <div class="footer-heading">Houses on Rent in Indianapolis</div>
                        <div class="footer-heading">Houses on Rent in Charlotte</div>
                        <div class="footer-heading">Houses on Rent in Seattle</div>
                        <div class="footer-heading">Houses on Rent in Denver</div>
                        <div class="footer-heading">Houses on Rent in Washington</div>
                        <div class="footer-heading">Houses on Rent in Nashville</div>
                        <div class="footer-heading">Houses on Rent in Oklahoma City</div>
                    </div>
                    <div class="footer-column">
                        <div class="footer-heading">Houses on Rent in El Paso</div>
                        <div class="footer-heading">Houses on Rent in Boston</div>
                        <div class="footer-heading">Houses on Rent in Portland</div>
                        <div class="footer-heading">Houses on Rent in Las Vegas</div>
                        <div class="footer-heading">Houses on Rent in Detroit</div>
                        <div class="footer-heading">Houses on Rent in Memphis</div>
                        <div class="footer-heading">Houses on Rent in Louisville</div>
                    </div>
                    <div class="footer-column">
                        <div class="footer-heading">Houses on Rent in Baltimore</div>
                        <div class="footer-heading">Houses on Rent in Milwaukee</div>
                        <div class="footer-heading">Houses on Rent in Albuquerque</div>
                        <div class="footer-heading">Houses on Rent in Tucson</div>
                        <div class="footer-heading">Houses on Rent in Fresno</div>
                        <div class="footer-heading">Houses on Rent in Kansas City</div>
                        <div class="footer-heading">Houses on Rent in Columbus</div>
                    </div>
                </div> */}
                    {/* <div style={{ borderBottom: "1.5px solid #ffffff", paddingTop: "20px" }} /> */}
                    <div className="d-flex justify-content-center mt-4">
                        <div>
                            <img src={FooterIcon} alt="" />
                        </div>
                        <div className="footer-last">2023 Govhome.com</div>
                    </div>
                </div>
            </div>

            <Modal isOpen={opneModal} style={styleWithRelist} overlayClassName="myoverlay">
                <div className="contact-modal ">
                    <div className="contact-heading">Contact Detail</div>
                    <Row style={{ padding: "15px 20px 0 20px" }}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <div className="message-heading">First Name</div>
                            <TextField
                                fullWidth
                                required
                                value={firstName}
                                onChange={(e) => handleFirstNameChange(e)}
                                id="outlined-required"
                            />

                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6} xxl={6}>
                            <div className="message-heading">Last Name</div>
                            <TextField
                                fullWidth
                                required
                                value={lastName}
                                onChange={(e) => handleLastNameChange(e)}
                                id="outlined-required"
                            />
                        </Col>
                        <Col lg={12}>
                            <div className="message-heading" style={{ marginTop: "20px" }}>Email Address</div>
                            <TextField
                                fullWidth
                                required
                                value={emailAddress}
                                onChange={(e) => handleEmailChange(e)}
                                id="outlined-required"
                            />
                            {(!continueButton && emailAddress !== "") &&
                                <div className="email-error">Please Enter a Vaild Email</div>
                            }
                        </Col>
                        <Col lg={12}>
                            <div className="message-heading" style={{ marginTop: "15px" }}>Message</div>
                            <textarea name="postContent" style={{ width: '100%', border: "1px solid black" }} value={message} onChange={(e) => handleMessageChange(e)} rows="5" />
                        </Col>
                        <Col lg={6} style={{ marginTop: "15px" }}>
                            <ContinueButton text={'Cancel'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontWeight={500} width={"100%"} onClick={() => closeModal()} />
                        </Col>
                        <Col lg={6} style={{ marginTop: "15px" }}>
                            <ContinueButton text={'Save'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'100%'} borderRadius={"4px"} fontWeight={600} onClick={() => handlePostContact()} />
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}
)

export default FooterSection
